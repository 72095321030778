<template>
  <div class="container">
    <template v-for="formItem in pageLayout">
      <div v-if="formItem.fmCode !== 'images'" :key="formItem.fmCode" class="form h-shadow-sm">
        <div class="form-title">
          <span class="title">{{ formItem.fmName }}</span>
        </div>
        <div class="form-content">
          <HYFormView :ref="formItem.fmCode + 'FormRef'" :config="formItem" :form="pageForm[formItem.fmCode]" :detailForm="detailForm"> </HYFormView>
        </div>
      </div>
    </template>
    <div class="h-flex" style="margin-top: 10px;margin-bottom: 10px;padding: 0px 5px;">
      <div class="back h-flex-1 h-text-center h-flex">
        <van-button round block type="primary" @click="cancel">返回订单</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import HYFormView from "@/components/FormView.vue";
import { request } from "@/api/service";
import scriptLib from "@/views/product/layout/orderScript";
import dayjs from "dayjs";
import { mapState } from "vuex";
import { getDictInfo } from "@/api/common/dict";
export default {
  name: "OrderDetail",
  components: { HYFormView },
  provide() {
    return {
      pageContext: this,
      pageForm: this.pageForm,
      pageLayout: this.pageLayout,
      pageLayoutMap: this.pageLayoutMap,
      PageScript: this.PageScript
    };
  },
  data() {
    return {
      productData: null,
      PageScript: scriptLib.call(this),
      pageForm: { rescue: {} },
      pageLayoutMap: {},
      pageLayout: [
        {
          id: "3654f39a-4926-4e1d-b4a7-b47b072a8df4",
          componentId: "61bc337361cda9549152fd00",
          fmCode: "rescue",
          fmName: "救援信息",
          formItems: [
            {
              component: "HInput",
              prop: "upstreamCompanyName",
              label: "上游单位",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7bf0e"
            },
            {
              component: "HSelect",
              prop: "rescueType",
              label: "是否预约",
              show: false,
              disabled: false,
              view: false,
              col: 12,
              rules: [],
              options: [],
              val: "1",
              dictCode: "COMMON_IF",
              id: "c356e4a7-9243-4cb0-beef-8f667185a27f"
            },
            {
              component: "HDatePicker",
              prop: "appointTime",
              label: "预约时间",
              show: true,
              disabled: false,
              readonly: true,
              view: false,
              col: 12,
              rules: [],
              val: null,
              filters: "dateFormat()",
              format: "YYYY-MM-DD HH:mm",
              maxOpt: [],
              id: "8b15382c-1296-4b6c-b74b-878a9b34ff38"
            },
            {
              component: "HInput",
              prop: "ownerName",
              label: "车主姓名",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b666"
            },
            {
              component: "HInput",
              prop: "phone",
              label: "手机号",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b777"
            },
            {
              component: "HInput",
              prop: "plateFrameNo",
              label: "车架号/车牌号",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b888"
            },
            {
              component: "HInput",
              prop: "projectName",
              label: "救援项目",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b999"
            },
            {
              component: "HInput",
              prop: "rescuePlateNo",
              label: "被救援车牌号",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b111"
            },
            {
              component: "HInput",
              prop: "plateModel",
              label: "厂牌型号",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b222"
            },
            {
              component: "HInput",
              prop: "faultType",
              label: "故障类型",
              show: false,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "c356e4a7-9243-4cb0-beef-51de4cf7b333"
            },
            {
              component: "HInput",
              prop: "businessNo",
              label: "第三方业务号",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b444"
            },
            {
              component: "HInput",
              prop: "rescueAddr",
              label: "救援地址",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b555"
            },
            {
              component: "HInput",
              prop: "destinationAddr",
              label: "救援目的地",
              show: true,
              disabled: false,
              view: "pageForm.rescue && pageForm.rescue.projectCode && pageForm.rescue.projectCode.charAt(0) === 'T'",
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b666"
            },
            {
              component: "HInput",
              prop: "predictKm",
              label: "预估公里数",
              show: true,
              disabled: false,
              view: "pageForm.rescue && pageForm.rescue.projectCode && pageForm.rescue.projectCode.charAt(0) === 'T'",
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b777"
            },
            {
              component: "HSelect",
              prop: "settleType",
              label: "结算类型",
              show: false,
              disabled: false,
              view: false,
              col: 12,
              rules: [],
              options: [],
              val: "1",
              dictCode: "RSC_SETTLE_TYPE",
              id: "c356e4a7-9243-4cb0-beef-8f6671851111"
            },
            {
              component: "HSelect",
              prop: "tollBear",
              label: "过路过桥费拖车公司承担",
              show: false,
              disabled: false,
              view: false,
              col: 12,
              rules: [],
              options: [],
              val: "1",
              dictCode: "COMMON_IF",
              id: "c356e4a7-9243-4cb0-beef-8f6671851112"
            },
            {
              component: "HInput",
              prop: "dispatchName",
              label: "派工人",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b773"
            },
            {
              component: "HSelect",
              prop: "difficultType",
              label: "困境处理方式",
              show: false,
              disabled: false,
              view: "pageForm.rescue && pageForm.rescue.projectCode && (pageForm.rescue.projectCode === 'Q01' || pageForm.rescue.projectCode === 'Q02')",
              col: 12,
              rules: [],
              options: [],
              val: "1",
              dictCode: "RSC_DIFFICULT_TYPE",
              id: "c356e4a7-9243-4cb0-beef-8f6671851116"
            },
            {
              component: "HInput",
              prop: "remark",
              label: "备注",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b775"
            }
          ],
          labelWidth: 120,
          initTooltipShow: false
        }
      ],
      detailForm: {}
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("user", ["info"]),
    scriptObj() {
      const pageScriptObj = new this.PageScript();
      pageScriptObj.pageForm = this.pageForm;
      pageScriptObj.pageLayout = this.pageLayout;
      pageScriptObj.pageLayoutMap = this.pageLayoutMap;
      pageScriptObj.pageContext = this;
      return pageScriptObj;
    }
  },
  async created() {
    await this.initData();
    this.init();
  },
  methods: {
    dayjs,
    cancel() {
      this.$router.replace({
        path: "/product/order/list"
      });
    },
    async init() {
      const { id } = this.$route.query;
      const res = await request({
        url: "/acs-rescue/order/downInfo/" + id
      });
      let faultTypes = res.faultType && res.faultType.length ? res.faultType.split(",") : [];
      const dicts = await getDictInfo("RSC_DIFFICULT_TYPE");
      let names = [];
      faultTypes.map(ft => {
        if (dicts.some(dict => dict.value === ft)) {
          names.push(dicts.filter(dict => dict.value === ft)[0].label);
        }
      });
      this.$set(this.pageForm, "rescue", { ...res, faultType: names.join(",") });
      this.detailForm = { rescue: { ...res, faultType: names.join(",") } };
    },
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
::v-deep .van-checkbox {
  display: inline-block;
}
.form {
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
  .form-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    .title {
      border-left: 5px solid $primary-color;
      padding: 0 8px;
    }
  }
}
.fail-reason {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: 微软雅黑;
  color: rgb(102, 102, 102);
  line-height: 30px;
  padding: 0px 20px;
  word-wrap: break-word;
  padding-bottom: 20px;
}
.submitBtn {
  height: 44px;
  .fixed {
    border-top: 1px solid $border-color;
    position: fixed;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.agreement ::v-deep {
  line-height: 12px;
  .van-checkbox__icon--disabled .van-icon {
    background-color: #fff;
  }
  .van-checkbox__icon--checked .van-icon {
    color: #fff;
    border-color: #57ccc2;
    background-color: #57ccc2;
  }
}
.none-tip {
  text-align: center;
  margin: 10px;
  border-radius: 5px;
  background-color: $white;
  ::v-deep .van-image {
    margin-top: 20px;
  }
}
</style>
